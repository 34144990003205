var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"align-start"},[_c('span',[_vm._v("Snowflake Task Status")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-n2 me-n3",attrs:{"icon":"","small":""}})],1),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.getTaskData,"item-key":"task"},scopedSlots:_vm._u([{key:"item.task",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block text--primary  font-weight-semibold text-truncate"},[_vm._v(_vm._s(item.task))])])]),_c('v-chip',{attrs:{"x-small":""}},[_vm._v(" "+_vm._s(item.database)+"."+_vm._s(item.schema)+" ")]),(item.status === 'suspended')?_c('v-chip',{staticClass:"error ml-3",attrs:{"x-small":""}},[_vm._v(" "+_vm._s('Suspended')+" ")]):_vm._e()]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"18","color":_vm.resolveColor(item.state)}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.resolveIcon(item.state))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.error || item.state))])])]}},{key:"item.lastRun",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.lastRun,"from"))+" ")]}},{key:"item.nextRun",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.nextRun,"from"))+" ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }