<template>
  <v-card>
    <v-row dense>
      <v-col
        class="ma-0"
        sm="6"
      >
        <!-- <v-card-title class="align-start">
          <span>Snowflake Users</span>
        </v-card-title> -->

        <!-- <v-card-subtitle class="mb-7">
          <span class="font-weight-semibold text--primary me-1">Users Statistics For {{ dateRangeFilter.name }}</span><v-icon color="success"></v-icon>
        </v-card-subtitle> -->
      </v-col>
      <v-col
        class="mt-5 mr-3"
        sm="5"
      >
      </v-col>
    </v-row>

    <v-card-text>
      <v-row>
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="6"
          sm="4"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="data.color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ data.icon }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-bold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import { colorLight, formatNumbers } from '@/functions'
import store from '@/store'
// eslint-disable-next-line object-curly-newline
import { mdiAccountGroup, mdiCogClockwise, mdiCurrencyUsd, mdiDotsVertical, mdiFilterVariant, mdiTrendingUp } from '@mdi/js'
import { computed } from '@vue/composition-api'
import _ from 'lodash'
import moment from 'moment-timezone'

export default {
  setup() {
    const dateRangeFilter = computed(() => store.getters.dateRangeFilter)

    const totalSnowflakeUsers = computed(() => {
      const users = store.state.snowflakeData.snowflakeUsers

      return users.length
    })
    const activeSnowflakeUsers = computed(() => {
      let users = store.state.snowflakeData.snowflakeUsers
      users = users.filter(f => new Date(f.lastLogin) >= moment(dateRangeFilter.value.startDate).toDate())

      return users.length
    })
    const totalExecutions = computed(() => {
      const getExecutionsData = store.getters['snowflakeData/snowflakeExecutionsByFilter']

      return _.sumBy(getExecutionsData, 'ex')
    })
    const totalCredits = computed(() => {
      const getConsumptionData = store.getters['snowflakeData/snowflakeConsumptionByFilter']

      return _.sumBy(getConsumptionData, 'cu')
    })

    const statisticsData = computed(() => [
      {
        title: 'Active Users',
        icon: mdiAccountGroup,
        color: 'primary',
        total: `${formatNumbers(activeSnowflakeUsers.value)} of ${formatNumbers(totalSnowflakeUsers.value)}`,
      },
      {
        title: 'Total Executions',
        icon: mdiCogClockwise,
        color: 'error',
        total: formatNumbers(totalExecutions.value),
      },
      {
        title: 'Credits Consumed',
        icon: mdiCurrencyUsd,
        color: 'warning',
        total: formatNumbers(totalCredits.value),
      },
    ])

    return {
      totalExecutions,
      colorLight,
      dateRangeFilter,
      statisticsData,

      // icons
      icons: {
        mdiFilterVariant,
        mdiTrendingUp,
        mdiDotsVertical,
      },
    }
  },
}
</script>
