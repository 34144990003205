<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Snowflake Statistics</span>

      <v-spacer></v-spacer>
      <span class="text-sm mr-3 mt-1">Total Size: {{ totalBytes }}</span><v-icon color="primary">
        {{ icons.mdiChartDonut }}
      </v-icon>
      <!-- <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon class="cursor-pointer">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn> -->
    </v-card-title>

    <v-card-subtitle class="mb-7">
      <span class="font-weight-semibold text--primary me-1">Data Freshness Is Increasing </span><v-icon color="success">
        {{ icons.mdiTrendingUp }}
      </v-icon>
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="6"
          sm="4"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation (data.title).color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ resolveStatisticsIconVariation (data.title).icon }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-bold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { formatBytes, formatNumbers } from '@/functions'
import store from '@/store'
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiChartDonut, mdiDatabase, mdiDotsVertical, mdiFileTableBoxMultiple, mdiTableMultiple, mdiTrendingUp } from '@mdi/js'
import { computed } from '@vue/composition-api'
import _ from 'lodash'

export default {
  setup() {
    const schemaTotals = computed(() => {
      const schemaData = store.state.snowflakeData.snowflakeTableFreshness
      const totalDatabases = schemaData.length
      let totalSchemas = 0
      let totalTables = 0
      schemaData.forEach(db => {
        const schemas = Object.keys(db.schemas)
        totalSchemas += schemas.length
        schemas.forEach(schema => {
          const tables = Object.keys(db.schemas[schema])
          totalTables += tables.length
        })
      })

      return { totalDatabases, totalSchemas, totalTables }
    })

    // const totalBytes = formatBytes(schemaTotals.value.totalBytes)

    const totalBytes = computed(() => {
      const getStorageData = store.state.snowflakeData.snowflakeStorageSummary
      const storage = _.sumBy(getStorageData, 'totalBytes')

      return formatBytes(storage)
    })
    const statisticsData = [
      {
        title: 'Databases',
        total: formatNumbers(schemaTotals.value.totalDatabases),
      },
      {
        title: 'Schemas',
        total: formatNumbers(schemaTotals.value.totalSchemas),
      },
      {
        title: 'Tables',
        total: formatNumbers(schemaTotals.value.totalTables),
      },
    ]

    const resolveStatisticsIconVariation = data => {
      if (data === 'Databases') return { icon: mdiDatabase, color: 'primary' }
      if (data === 'Schemas') return { icon: mdiFileTableBoxMultiple, color: 'success' }
      if (data === 'Tables') return { icon: mdiTableMultiple, color: 'warning' }

      return { icon: mdiAccountOutline, color: 'success' }
    }

    return {
      totalBytes,
      schemaTotals,
      statisticsData,
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiTrendingUp,
        mdiDotsVertical,
        mdiChartDonut,
      },
    }
  },
}
</script>
