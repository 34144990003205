<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Recent Schema Changes</span>

      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon
          size="22"
        >
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-timeline
        dense
        class="timeline-custom-dense"
      >
        <v-timeline-item
          color="error"
          :icon="icons.mdiTableRemove"
        >
          <div class="d-flex justify-space-between align-center flex-wrap">
            <h4 class="font-weight-semibold me-1">
              Removed Table: "NEWTABLE"
            </h4>
            <small class="text-no-wrap">Today</small>
          </div>
          <p class="mb-0">
            Removed Table WORK.TYLERDELANGE.NEWTABLE
          </p>
        </v-timeline-item>
        <v-timeline-item
          color="error"
          :icon="icons.mdiTableColumnRemove"
        >
          <div class="d-flex justify-space-between align-center flex-wrap">
            <h4 class="font-weight-semibold me-1">
              Removed Columns from "TESTDB"
            </h4>
            <small class="text-no-wrap">Yesterday</small>
          </div>
          <p class="mb-0">
            [CREATED, MODIFIED, TIMESTAMP] were removed from WORK.TYLERDELANGE.TESTDB.
          </p>
        </v-timeline-item>
        <v-timeline-item
          color="success"
          :icon="icons.mdiTableColumnPlusAfter"
        >
          <div class="d-flex justify-space-between align-center flex-wrap">
            <h4 class="font-weight-semibold me-1">
              Added New Columns to "NEWTABLE"
            </h4>
            <small class="text-no-wrap">Saturday</small>
          </div>
          <p class="mb-0">
            [CREATED, MODIFIED, TIMESTAMP]: Columns were added to WORK.TYLERDELANGE.NEWTABLE
          </p>
        </v-timeline-item>
        <v-timeline-item
          color="success"
          :icon="icons.mdiTablePlus"
        >
          <div class="d-flex justify-space-between align-center flex-wrap">
            <h4 class="font-weight-semibold me-1">
              Added New Table: "NEWTABLE"
            </h4>
            <small class="text-no-wrap">Saturday</small>
          </div>
          <p class="mb-0">
            New Table Added: WORK.TYLERDELANGE.NEWTABLE
          </p>
        </v-timeline-item>
        <v-timeline-item
          color="info"
          :icon="icons.mdiTableEdit"
        >
          <div class="d-flex justify-space-between align-center flex-wrap">
            <h4 class="font-weight-semibold me-1">
              Column Datatype Changed for "COLUMNTEST"
            </h4>
            <small class="text-no-wrap">Friday</small>
          </div>
          <p class="mb-0">
            The datatype for column "COLUMNTEST" in WORK.TYLERDELANGE.TESTING was changed from 'STRING' to 'NUMBER'
          </p>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import {
  mdiDotsVertical,
  mdiTableRemove,
  mdiTableColumnRemove,
  mdiTableColumnPlusAfter,
  mdiTablePlus,
  mdiTableEdit,
} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiDotsVertical,
        mdiTableRemove,
        mdiTableColumnRemove,
        mdiTableColumnPlusAfter,
        mdiTablePlus,
        mdiTableEdit,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.theme--dark.v-timeline::before {
  margin-left: 7px;
}
.theme--light.v-timeline::before {
  margin-left: 7px;
}
</style>
