<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Consumption by Warehouse</span>
      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <vue-apex-charts
            id="chart-sales-overview"
            :options="chartOptions"
            :series="chartData"
            class="h-full d-flex align-center"
          ></vue-apex-charts>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          class="d-flex flex-column justify-center"
        >
          <div class="d-flex align-center">
            <v-avatar
              class="v-avatar-light-bg primary--text"
              rounded
              size="40"
            >
              <v-icon
                size="30"
                color="primary"
              >
                {{ icons.mdiCurrencyUsd }}
              </v-icon>
            </v-avatar>
            <div class="ms-4 d-flex flex-column">
              <p class="text--primary mb-0 text-base">
                Expenses {{ dateRangeFilter.name }}
              </p>
              <span class="text--primary font-weight-semibold text-xl">${{ consumptionByWarehouse.totalCost.toLocaleString('en-US') }}</span>
            </div>
          </div>

          <v-divider class="my-6"></v-divider>

          <table class="sales-overview-stats-table">
            <tr>
              <td>
                <div class="mb-0 text-xs">
                  <div class="stats-dot primary d-inline-block rounded-circle me-2"></div>
                  <span>{{ consumptionByWarehouse.warehouses[0] }}</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4">${{ Math.round(consumptionByWarehouse.expenses[0]).toLocaleString('en-US') }}</span>
              </td>
              <td>
                <div class="mb-0 text-xs">
                  <div class="stats-dot primary d-inline-block rounded-circle me-2"></div>
                  <span>{{ consumptionByWarehouse.warehouses[1] }}</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4">${{ Math.round(consumptionByWarehouse.expenses[1]).toLocaleString('en-US') }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <div class="mb-0 text-xs">
                  <div class="stats-dot primary d-inline-block rounded-circle me-2"></div>
                  <span>{{ consumptionByWarehouse.warehouses[3] }}</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4">${{ Math.round(consumptionByWarehouse.expenses[3]).toLocaleString('en-US') }}</span>
              </td>
              <td>
                <div class="mb-0 text-xs">
                  <div class="stats-dot primary d-inline-block rounded-circle me-2"></div>
                  <span>{{ consumptionByWarehouse.warehouses[4] }}</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4">${{ Math.round(consumptionByWarehouse.expenses[4]).toLocaleString('en-US') }}</span>
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import { formatNumbers } from '@/functions'
import store from '@/store'
import { addAlpha, getVuetify } from '@core/utils'
import { mdiCurrencyUsd, mdiDotsVertical } from '@mdi/js'
import { computed } from '@vue/composition-api'
import _ from 'lodash'

export default {
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
  },
  setup() {
    const $vuetify = getVuetify()
    const contractDetails = computed(() => store.state.snowflakeData.snowflakeContractDetails)

    const dateRangeFilter = computed(() => store.state.dateRangeFilter.filter(f => f.active)[0])

    const consumptionByWarehouse = computed(() => {
      let getWarehouseConsumption = store.getters['snowflakeData/snowflakeConsumptionByFilter']
      getWarehouseConsumption = _.orderBy(getWarehouseConsumption, 'cu', 'desc')
      const warehouses = getWarehouseConsumption.map(m => m.warehouse)
      const credits = getWarehouseConsumption.map(m => m.cu)
      const expenses = getWarehouseConsumption.map(m => m.cu * contractDetails.value.creditCost)
      const totalCredits = _.sumBy(credits)
      const totalCost = Math.round(_.sumBy(expenses))

      return {
        warehouses,
        credits,
        expenses,
        totalCredits,
        totalCost,
      }
    })

    const chartOptions = computed(() => ({
      tooltip: {
        y: {
          formatter: value => formatNumbers(value, 0),
        },
      },
      labels: consumptionByWarehouse.value.warehouses,
      colors: [$vuetify.theme.currentTheme.primary, addAlpha($vuetify.theme.currentTheme.primary, 0.9), addAlpha($vuetify.theme.currentTheme.primary, 0.8), addAlpha($vuetify.theme.currentTheme.primary, 0.7), addAlpha($vuetify.theme.currentTheme.primary, 0.6), addAlpha($vuetify.theme.currentTheme.primary, 0.5), addAlpha($vuetify.theme.currentTheme.primary, 0.4), addAlpha($vuetify.theme.currentTheme.primary, 0.3), addAlpha($vuetify.theme.currentTheme.primary, 0.2), addAlpha($vuetify.theme.currentTheme.primary, 0.1)],
      chart: {
        type: 'donut',
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: '70%',
            labels: {
              show: true,
              name: {
                fontSize: '14px',
                offsetY: 25,
              },
              value: {
                fontSize: '2.125rem',
                fontWeight: 600,

                offsetY: -15,

                formatter(val) {
                  if (Number.isNaN(val)) {
                    return val
                  }

                  return formatNumbers(Number(val))
                },
              },
              total: {
                show: true,
                label: 'Credits Used',
                color: 'rgba(94, 86, 105, 0.68)',
                formatter() {
                  return formatNumbers(consumptionByWarehouse.value.totalCredits)
                },
              },
            },
          },
        },
      },
    }))

    const chartData = consumptionByWarehouse.value.credits

    return {
      dateRangeFilter,
      consumptionByWarehouse,
      chartOptions,
      chartData,
      icons: {
        mdiDotsVertical,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>

<style lang="scss">
#chart-sales-overview {
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }
      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}

.sales-overview-stats-table {
  width: 100%;
  td {
    padding-bottom: 1rem;
  }

  .stats-dot {
    padding: 0.33rem;
  }

  // Set opacity of dots
  tr {
    &:nth-of-type(1) {
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.7;
        }
      }
    }
    &:nth-of-type(2) {
      td:nth-of-type(1) {
        .stats-dot {
          opacity: 0.5;
        }
      }
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.15;
        }
      }
    }
  }
}
</style>
